import Layout from "../Layout";
import ServicesCards from "../ServicesCards";
import ProductCards from "../ProductCards";
import FormLink from "./FormLink";
import Footer from "../Footer";
import Helmet from "react-helmet";

const Services = () => {
    return (
        <Layout>
            <Helmet> 
                <meta
                    name="description"
                    content="ZimSativa seeks to be a fully vertically integrated agribusiness, being at the foundation and pinnacle of the value chain. We grow industrial hemp to serve the textile, nutrition and stock feed markets."
                />
                <title>ZimSativa | Services</title>
            </Helmet>
            <div className="container">
                <div className="center">
                    <h4 className="make-bold d-g-text heading-stroke make-inline-block mt-3">Our services</h4>
                    <p className="grey-text text-darken-2 constrain-p">ZimSativa seeks to be a fully vertically integrated agribusiness, being at the foundation and pinnacle of the value chain. We grow industrial hemp to serve the <b>textile, nutrition and stock feed</b> markets. With ongoing research we seek to also use hemp in the <b>construction sector</b> as well.</p>
                </div>
            </div>
            <div className="lime-cloud"></div>
            <ServicesCards />
            <FormLink />
            <ProductCards />
            <div className="mb-3"></div>
            <Footer />
        </Layout>
    );
}

export default Services;
