import { HashLink } from 'react-router-hash-link';

const TeamLink = () => {
    return ( 
        <div className="main-background-2 mt-3">
            <div className="container">
                <div className="team-link">
                    <div>
                        <h5 className="make-bold make-inline-block grey-text text-lighten-4">About Our Team</h5>
                        <p className="grey-text text-lighten-4">We draw our talent from a wide base of professions and expertise. Each of the 10 directors that make up the board are young professionals...</p>
                    </div>
                    {/* <Link 
                        to="/our-team"
                        className="btn team-link-btn transparent make-inline-block mt-1 mb-2 z-depth-0"
                    >Read More</Link> */}
                    <HashLink 
                        to="/our-team#team"
                        className="btn team-link-btn transparent make-inline-block mt-1 mb-2 z-depth-0"
                    >Read More</HashLink>
                </div>
            </div>
        </div>
     );
}
 
export default TeamLink;