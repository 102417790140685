import React from 'react'
import Footer from '../Footer';
import Layout from '../Layout';
import ProductCards from '../ProductCards';
import ServicesCards from '../ServicesCards';
import TeamLink from '../TeamLink';
import IntroHome from './Intro';
import { Helmet } from 'react-helmet';

const Home = () => {
    return (
        <Layout >
            <Helmet> 
                <meta
                    name="description"
                    content="ZimSativa is a leading integrated Hemp Company with robust operations across the Hemp value chain."
                />
                <title>ZimSativa | Home</title>
            </Helmet>
            <div className="translatedY">
                <IntroHome />
                <ServicesCards />
                <TeamLink />
                <ProductCards />
            </div>
            <Footer />
        </Layout>
    )
}

export default Home;
