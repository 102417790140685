import { HashLink } from 'react-router-hash-link';

const FormLink = () => {
    return (
        <div className="main-background-2 mt-3">
            <div className="container">
                <div className="team-link">
                    <div>
                        <h5 className="make-bold make-inline-block grey-text text-lighten-3">Our Philosophy</h5>
                        <p className="grey-text text-lighten-3">Riding on the backbone of the established industrial hemp farming bill passed in Zimbabwe in 2020, We believes the future of Zimbabwe and the world at large lies in sustainable agriculture and industry development. </p>
                    </div>
                     <HashLink 
                        to="/contact-us#form"
                        className="btn team-link-btn transparent make-inline-block mt-1 mb-2 z-depth-0"
                    >Get In Touch</HashLink>
                </div>
            </div>
        </div>
    );
}

export default FormLink;
