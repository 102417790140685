import Nav from './Nav';
import { motion } from 'framer-motion';

const topicVariants = {
    hidden: {

        opacity: 0
    },
    visible: {

        opacity: 1,
        transition: { type: 'spring', duration: 2.75, delay: 2.1}
    }
}

const Layout = ({ children }) => {
    return (
        <>
            <header>
                <Nav />
                <motion.p
                    variants={topicVariants}
                    initial="hidden"
                    animate="visible"
                    className="header-topic make-bold white-text shadow"
                ></motion.p>
            </header>
                {children}
       </>
    );
}

export default Layout;
