import { BsCheckCircle, BsPersonCheckFill } from 'react-icons/bs';
import { ImLab } from 'react-icons/im';
import { TiGroup } from 'react-icons/ti';
import { FaTasks, FaGlobeAfrica } from 'react-icons/fa';

const Values = () => {
    return (
        <>
            <div className="container center">
                <h4 className="make-bold d-g-text heading-stroke make-inline-block mt-3 mb-2">Our Values</h4>
            </div>
            <div className="container">
                <div className="values-cont mb-3">
                    <div className="value">
                        <div className="valign-wrapper">
                            <BsCheckCircle size="4.3rem" className="l-g-text mr-1"/>
                            <span>
                                <h5 className="make-medium make-inline-block m-0 d-g-text">Quality</h5>
                                <p className="mt-05 smaller-text mb-0">Our work is validated by data-informed scientific rigor and standards.</p>
                            </span>
                        </div>
                    </div>
                    <div className="value">
                        <div className="valign-wrapper">
                            <BsPersonCheckFill size="4.3rem" className="l-g-text mr-1"/>
                            <span>
                                <h5 className="make-medium make-inline-block m-0 d-g-text">Integrity</h5>
                                <p className="mt-05 smaller-text mb-0">We are admired for our commitment to honesty and transparency.</p>
                            </span>
                        </div>
                    </div>
                    <div className="value">
                        <div className="valign-wrapper">
                            <ImLab size="4.1rem" className="l-g-text mr-1"/>
                            <span>
                                <h5 className="make-medium make-inline-block m-0 d-g-text">Innovation</h5>
                                <p className="mt-05 smaller-text mb-0">We study, internalize and research to have a well-reasoned view.</p>
                            </span>
                        </div>
                    </div>
                    <div className="value">
                        <div className="valign-wrapper">
                            <TiGroup size="4.3rem" className="l-g-text mr-1"/>
                            <span>
                                <h5 className="make-medium make-inline-block m-0 d-g-text">Leadership</h5>
                                <p className="mt-05 smaller-text mb-0">Leaders pull the wagon alongside the team. They don’t direct orders from it.</p>
                            </span>
                        </div>
                    </div>
                    <div className="value">
                        <div className="valign-wrapper">
                            <FaTasks size="4.3rem" className="l-g-text mr-1"/>
                            <span>
                                <h5 className="make-medium make-inline-block m-0 d-g-text">Accountability</h5>
                                <p className="mt-05 smaller-text mb-0">We hold ourselves accountable for outcomes, good and bad.</p>
                            </span>
                        </div>
                    </div>
                    <div className="value">
                        <div className="valign-wrapper">
                            <FaGlobeAfrica size="4.1rem" className="l-g-text mr-1"/>
                            <span>
                                <h5 className="make-medium make-inline-block m-0 d-g-text">Eco-Sustainability</h5>
                                <p className="mt-05 smaller-text mb-0">We work smartly and take care of our environment.</p>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
}

export default Values;
