import { useState } from "react";
import { IoSend } from "react-icons/io5";
import { ImLocation2 } from "react-icons/im";
import { IoCallSharp } from "react-icons/io5";
import { AiTwotoneMail  } from "react-icons/ai";
import { FaLinkedinIn  } from "react-icons/fa";
import * as EmailValidator from 'email-validator';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if(name !== '' || surname !== '' || email !== '' || message !== '') {
            setError(null);
            if(!EmailValidator.validate(email)){
                setError('Please provide a valid email.');
            } else {
                setError(null);
                document.getElementById('Btn').innerHTML = "Submiting";
                document.querySelector('.btn-cont').setAttribute('disabled', true);

                //emailjs
                emailjs.sendForm('service_mub22jz', 'template_adejdrd', e.target, 'user_KoNMSajGWXzs1PJtgzR6h')
                .then((result) => {
                    //reset form 
                    setName('')
                    setEmail('');
                    setSurname('');
                    setPhone('');
                    setMessage('');

                    //on success
                    document.getElementById('Btn').innerHTML = "Submit";
                    document.querySelector('.btn-cont').setAttribute('disabled', false);
                    toast.success("You Message has been sent Successfully!");
                    
                }, (error) => {
                    setError(error.text);
                    document.getElementById('Btn').innerHTML = "Submit";
                    document.querySelector('.btn-cont').setAttribute('disabled', true);
                    
                 });
            }
        } else {
            setError('Please fill out all the form fields.')
        }
    }
    var d = new Date();
    var year = d.getFullYear();

    return (
        <footer className="page-footer grey darken-4">
            
            <div className="container">
                <div className="row">
                    <div className="col s12 m7 l6">
                        <form className="footer-form mt-2" onSubmit={handleSubmit}>
                            <ToastContainer />
                            <div className="row">
                                <div className="input-field col s12 m6">
                                    <input name="user_name" id="name" type="text" onChange={(e) => setName(e.target.value)} value={name} required/>
                                   <label htmlFor="name">First Name</label>
                                </div>
                                <div className="input-field col s12 m6">
                                    <input name="user_surname" id="surname" type="text" onChange={(e) => setSurname(e.target.value)} value={surname} required />
                                    <label htmlFor="surname">Surname</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s12 m6">
                                    <input name="user_email" id="email" type="email"  className="validate" onChange={(e) => setEmail(e.target.value)} value={email} required />
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="input-field col s12 m6">                   
                                    <input name="telephone" id="telephone" type="tel" onChange={(e) => setPhone(e.target.value)} value={phone}  />
                                    <label htmlFor="telephone">Phone Number (opt)</label>
                                </div>
                                <div className="input-field col s12 m6 hide">                   
                                    <input name="mairi" type="text" defaultValue="zimsativa@gmail.com"/>
                                </div>
                            </div>
                            <div className="input-field col s12">
                                <textarea name="message" id="textarea1" className="materialize-textarea" onChange={(e) => setMessage(e.target.value)} value={message} required></textarea>
                                <label htmlFor="textarea1">Message</label>
                            </div>
                            {
                                error && <p className="main-color-text tiny-text mt-5">{error}</p>
                            }
                            <div className="center">
                                <button className="btn btn-cont main-background my-3 waves-effect waves-dark">
                                    <span className="icon mr-05">
                                        <IoSend color="white"/>
                                    </span>
                                    <span className="white-text" id="Btn">Submit</span>
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="col s12 m5 l4 offset-l1">
                        <h5 className="make-medium grey-text text-lighten-2 heading-stroke make-inline-block mt-3 mb-2">Kindly Get In Touch</h5>
                        <div className="footer-link">
                            <span className="icon-bg mr-1 grey darken-3">
                                <ImLocation2 />
                            </span>
                            <span className="grey-text text-lighten-1">8 Edge Close, Hatfield <br/>Harare, Zimbabwe</span>
                        </div>
                        <div className="footer-link">
                            <span className="icon-bg mr-1 grey darken-3">
                                <IoCallSharp />
                            </span>
                            <span><a className="grey-text text-lighten-1" href="tel:+263 77 674 6279">+263 77 674 6279</a></span>
                        </div>
                        <div className="footer-link">
                            <span className="icon-bg mr-1 grey darken-3">
                                <IoCallSharp />
                            </span>
                            <span ><a className="grey-text text-lighten-1" href="tel:+971 54 387 2087">+971 54 387 2087</a></span>
                        </div>
                        <div className="footer-link">
                            <span className="icon-bg mr-1 grey darken-3">
                                <AiTwotoneMail />
                            </span>
                            <span ><a className="grey-text text-lighten-1" href="mailto:info@zimsativa.com">info@zimsativa.com</a></span>
                        </div>
                        <div className="footer-link">
                            <span className="icon-bg mr-1 grey darken-3">
                                <FaLinkedinIn />
                            </span>
                            <span><a className="grey-text text-lighten-1" href="https://www.linkedin.com/company/zimsativa" target="_blank" rel="noreferrer">ZimSativa</a></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer-copyright">
                <div className="container center">
                    <p className="grey-text text-darken-1">
                        &copy; { year } ZimSativa. All Rights Reserved | Website by <a className="grey-text" href="http://www.scalacoms.co.zw" target="_blank" rel="noreferrer">Scalalcoms</a>
                    </p>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
