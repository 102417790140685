import icon1 from '../assets/knitting-icon.svg';
import icon2 from '../assets/nutrition-icon.svg';
import icon3 from '../assets/cow-icon.svg';

const ServicesCards = () => {
    return (
        <div className="container">
            <div className="service-cards z-depth-1 mt-3">
                <div className="service-card-1">
                    <img src={icon1} alt="Textile Icon" />
                    <h5 className="make-bold make-inline-block grey-text text-darken-2">Textile</h5>
                    <p className="grey-text text-darken-2">Hemp fabric is a type of textile that is made using fibers from the stalks of the Cannabis sativa plant.</p>
                </div>
                <div className="service-card-2 main-background">
                    <img src={icon2} alt="Nutrition Icon" />
                    <h5 className="make-bold make-inline-block grey-text text-lighten-3">Nutrition</h5>
                    <p className="grey-text text-lighten-3">Many people consider hemp seeds to be a superfood. The seeds have a rich nutritional profile and provide a range of health benefits.</p>
                </div>
                <div className="service-card-3">
                    <img src={icon3} alt="Cow Icon" />
                    <h5 className="make-bold make-inline-block grey-text text-darken-2">Stock Feed</h5>
                    <p className="grey-text text-darken-2">Highly regarded crude protein content of the hemp seed cake can produce a protein substitute in the diet of pets and livestock.</p>
                </div>
            </div>
        </div>
    )
}

export default ServicesCards;
