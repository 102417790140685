import { motion } from 'framer-motion';
import Layout from "../Layout";
import bw from '../../assets/B-Washaya.jpg';
import dm from '../../assets/D-Magaya.jpg';
import dm2 from '../../assets/David-Magaya.jpg';
import mm from '../../assets/M-Mafo.jpg';
import bk from '../../assets/B-Kamhanda.jpg';
import cz from '../../assets/C-Zijena.jpg';
import sm from '../../assets/S-Mutatapasi.jpg';
import jp from '../../assets/J-Mpofu.jpg';
import Helmet from 'react-helmet';
import placeholder from '../../assets/placeholder.svg'

const Team = () => {
    const data = [
        {
            name: "Ben Washaya",
            position: "Chairman/CEO",
            imgLink: bw
        },
        {
            name: "Tinashe Mazorodze",
            position: "Non Executive Director",
            imgLink: 'dm'
        },
        {
            name: "Dale Magaya",
            position: "Business Development Director",
            imgLink: dm
        },
        {
            name: "Joshua Mpofu",
            position: "Board Treasurer/Financial Controller",
            imgLink: jp
        },
        {
            name: "Micheal Mafo",
            position: "Chief Operations Officer",
            imgLink: mm
        },
        {
            name: "David Magaya",
            position: "Corporate Governance Director",
            imgLink: dm2
        },
        {
            name: "Brain Kamhanda",
            position: "Board Secretary/Chief Technology Officer",
            imgLink: bk
        },
        {
            name: "Samora",
            position: "MD/Chief Legal Officer",
            imgLink: 'nnn'
        },
        {
            name: "Clifford Zijena",
            position: "Chief Marketing Officer",
            imgLink: cz
        },
        {
            name: "Simba Mutatapasi",
            position: "Chief Financial Officer",
            imgLink: sm
        }

    ]
    return (
        <Layout>
            <Helmet> 
                <meta
                    name="description"
                    content="We draw our talent from a wide base of professions and expertise. Each of the 10 directors that make up the board are young professionals."
                />
                <title>ZimSativa | Our Team</title>
            </Helmet>
            <div className="container center">
                <h4 className="make-bold d-g-text heading-stroke make-inline-block mt-3 mb-2" id="team">Our Team</h4>
            </div>
            <div className="dotted-bg">
            <div className="container">
                <div className="team-cards mb-2" id="team">
                    {
                        data && data.map(person => (
                            <motion.div 
                                className="person-card" 
                                key={person.name}
                                whileHover={{ scale: 1.05, rotateZ: 3}}
                            >
                                <div className="img-cont">
                                    <img 
                                        src={person.imgLink} 
                                        alt={person.name} 
                                        className="circle" 
                                        onError={(e) => e.target.setAttribute('src', placeholder)}
                                    />
                                </div>
                                <div className="center">
                                    <h6 className="make-bold mt-1 mb-1 grey-text text-darken-2 z-index-4">{person.name}</h6>
                                    <p className="make-medium mt-1 mb-1 d-g-text z-index-4">{person.position}</p>
                                </div>
                            </motion.div>     
                        ))
                    }               
                </div>
            </div>

            </div>
        </Layout>
    );
}

export default Team;
