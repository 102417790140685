import Helmet from "react-helmet";
import Footer from "../Footer";
import Layout from "../Layout";
import AboutIntro from "./AboutIntro";
import Values from "./Values";

const About = () => {
    return (
        <Layout>
            <Helmet> 
                <meta
                    name="description"
                    content="ZimSativa was founded in 2020 by a group of focused young professionals who wanted to prove that there could be a better way to do things without living scathing mark on the environment – showing that it is possible to build a sustainable future whilst at the same time improving the lives of the community around you."
                />
                <title>ZimSativa | About Us</title>
            </Helmet>
            <AboutIntro />
            <Values />
            <Footer />
        </Layout>
    );
}

export default About;
