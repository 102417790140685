import { useState } from "react";
import Helmet from "react-helmet";
import { FaLinkedinIn } from "react-icons/fa";
import { IoCallSharp, IoSend } from "react-icons/io5";
import Layout from "../Layout";
import { motion } from 'framer-motion';
import * as EmailValidator from 'email-validator';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const boxVariants = {
    hidden: {
        y: 50,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { type: 'spring', duration: 1.75, delay: 0.8 }
    }
}

const Contact = () => {
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [error, setError] = useState(null);
    const [message, setMessage] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();

        if(name !== '' || surname !== '' || email !== '' || message !== '') {
            setError(null);
            if(!EmailValidator.validate(email)){
                setError('Please provide a valid email.');
            } else {
                setError(null);
                document.getElementById('Btn').innerHTML = "Submiting";
                document.querySelector('.btn-cont').setAttribute('disabled', true);

                //emailjs
                emailjs.sendForm('service_mub22jz', 'template_adejdrd', e.target, 'user_KoNMSajGWXzs1PJtgzR6h')
                .then((result) => {
                    //reset form 
                    setName('')
                    setEmail('');
                    setSurname('');
                    setPhone('');
                    setMessage('');

                    //on success
                    document.getElementById('Btn').innerHTML = "Submit";
                    toast.success("You Message has been sent Successfully!");
                    document.querySelector('.btn-cont').setAttribute('disabled', false);
                    
                }, (error) => {
                    setError(error.text);
                    document.getElementById('Btn').innerHTML = "Submit";
                    document.querySelector('.btn-cont').setAttribute('disabled', false);
                    
                 });
            }
        } else {
            setError('Please fill out all the form fields.')
        }
    }

    return (
        <Layout>
            <Helmet> 
                <meta
                    name="description"
                    content="ZimSativa is a leading integrated Hemp Company with robust operations across the Hemp value chain."
                />
                <title>ZimSativa | Contact Us</title>
            </Helmet>
            <ToastContainer />
             <div className="translatedY">
                <div className="container">
                    <motion.div 
                        className="contacts-cont z-depth-1 grey lighten-5"
                        variants={boxVariants}
                        initial="hidden"
                        animate="visible"
                    >
                        <div className="contacts">
                            <div className="contact-box grey lighten-5">
                                <span className="icon-bg-2 grey ">
                                    <IoCallSharp size="1.5rem"  className="white-text"/>
                                </span>
                                <span>
                                    <h5 className="make-medium make-inline-block m-0 d-g-text">Call Us Now</h5>
                                    <div>
                                        <a className="grey-text text-darken-2" href="tel:+263 77 674 6279">+263 77 674 6279</a>
                                    </div>
                                    <div>
                                        <a className="grey-text text-darken-2" href="tel:+971 54 387 2087">+971 54 387 2087</a>
                                    </div>
                                </span>
                            </div>
                            <div className="contact-box grey lighten-5">
                                <span className="icon-bg-2 grey ">
                                    <FaLinkedinIn size="1.5rem"  className="white-text"/>
                                </span>
                                <span>
                                    <h5 className="make-medium make-inline-block m-0 d-g-text">Follow Us</h5>
                                    <div>
                                        <a className="grey-text text-darken-2" href="https://www.linkedin.com/company/zimsativa" target="_blank" rel="noreferrer">ZimSativa</a>
                                    </div>
                                </span>
                            </div>
                            <div>
                                <h5 className="make-medium d-g-text mb-1 mt-1">Address:</h5>
                                <span className="grey-text text-darken-2">8 Edge Close, Hatfield <br/>Harare, Zimbabwe</span>
                            </div>
                        </div>
                        <div className="contacts-form" id="form">
                            <h5 className="make-medium grey-text text-darken-2 heading-stroke make-inline-block m-0">Kindly Write to Us</h5>
                            <form className="contacts-form mt-2" onSubmit={handleSubmit}>
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <input name="user_name" id="name" type="text" onChange={(e) => setName(e.target.value)} value={name} required/>
                                        <label htmlFor="name">First Name</label>
                                    </div>
                                    <div className="input-field col s12 m6">
                                        <input name="user_surname" id="surname" type="text" onChange={(e) => setSurname(e.target.value)} value={surname} required />
                                        <label htmlFor="surname">Surname</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col s12 m6">
                                        <input name="user_email" id="email" type="email"  className="validate" onChange={(e) => setEmail(e.target.value)} value={email} required />
                                        <label htmlFor="email">Email</label>
                                    </div>
                                    <div className="input-field col s12 m6">                   
                                        <input name="telephone" id="telephone" type="tel" onChange={(e) => setPhone(e.target.value)} value={phone}  />
                                        <label htmlFor="telephone">Phone Number (opt)</label>
                                    </div>
                                    <div className="input-field col s12 m6 hide">                   
                                        <input name="mairi" type="text" defaultValue="zimsativa@gmail.com" />
                                    </div>
                                </div>
                                <div className="input-field col s12">
                                    <textarea name="message" id="textarea1" className="materialize-textarea" onChange={(e) => setMessage(e.target.value)} value={message} required></textarea>
                                    <label htmlFor="textarea1">Message</label>
                                </div>
                                {
                                    error && <p className="red-text small-text mt-1">{error}</p>
                                }
                                <div className="center">
                                    <button className="btn btn-cont main-background my-3 waves-effect waves-dark">
                                        <span className="icon mr-05">
                                            <IoSend color="white"/>
                                        </span>
                                        <span id="Btn" className="white-text">Submit</span>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </motion.div>
                </div>
            </div>
            <footer></footer>
        </Layout>
    );
}

export default Contact;
