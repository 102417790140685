import shoes from '../assets/hemp-shoes.jpeg';
import protein from '../assets/hemp-protein.jpeg';
import cattle from '../assets/cattle.jpg';

const ProductCards = () => {
    return (
        <div className="container center">
            <h5 className="make-bold d-g-text heading-stroke make-inline-block mt-3">Some Hemp Products</h5>
            <p className="grey-text text-darken-2 constrain-p">There are a wide range of products that Hemp can produce. In our ongoing research we seek to also use hemp in the construction sector as well.</p>
            <div className="product-cards mt-2">
                <div className="product-card">
                    <div className="product-img">
                        <img src={shoes}alt="Hemp Shoes" />
                    </div>
                    <div className="product-caption">
                        <p className="smaller-text white-text">This durable shoe fabric was made from hemp.</p>
                    </div>
                </div>
                <div className="product-card">
                    <div className="product-img">
                        <img src={protein}alt="Hemp Protien Powder" />
                    </div>
                    <div className="product-caption">
                        <p className="smaller-text white-text">Hemp is the perfect vegan protien Powder.</p>
                    </div>
                </div>
                <div className="product-card">
                    <div className="product-img">
                        <img src={cattle}alt="Cattle" />
                    </div>
                    <div className="product-caption">
                        <p className="smaller-text white-text">Hempseed feed stock products are highly nutriotious.</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCards
