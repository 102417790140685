
import { Redirect, Route, Switch } from 'react-router-dom'
import About from './components/About/About'
import Contact from './components/ContactUs/Contact'
import Home from './components/Home/Home'
import Team from './components/OurTeam/Team'
import Services from './components/Services/Services'

function App() {
  return (
    <div className="App">
      <Switch>
        <Route exact path="/">
          <Home />
        </Route>
        <Route path="/about">
          <About />
        </Route>
        <Route path="/services">
          <Services />
        </Route>
        <Route path="/our-team">
          <Team />
        </Route>
        <Route path="/contact-us">
          <Contact />
        </Route>
        <Route path="/*">
          <Redirect to="/" />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
