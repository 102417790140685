import { useEffect, useState } from "react";
import { Link, useLocation } from 'react-router-dom';
import M from 'materialize-css/dist/js/materialize.min';
import logo from '../assets/logo-zimsativa.png';
import { motion, useAnimation } from 'framer-motion';
import $ from 'jquery';


const logoBgVariants = {
    hidden: {
        width: 0,
        opacity: 0.8
    },
    visible: {
        width: '40%',
        opacity: 1,
    }
}
const logoBgVariants2 = {
    hidden: {
        width: 0,
        opacity: 0.8
    },
    visible: {
        width: '39%',
        opacity: 1,
    }
}
const logoBgVariants3 = {
    hidden: {
        width: 0,
        opacity: 0.8
    },
    visible: {
        width: '37%',
        opacity: 1,
    }
}

const Nav = () => {
    const [width, setWidth] = useState(window.innerWidth);

    const Animation = useAnimation();
    const navTextAnimation = useAnimation();
    const location = useLocation();

    window.addEventListener('resize', () => {
        setWidth(window.innerWidth);
    });

    window.addEventListener('scroll', () => {
        if(window.innerWidth >= 992 && window.scrollY > 300) {
            Animation.start({
                width: '100%',
              
            });
            $('.desktop-links li a').removeClass('white-text');
            $('.desktop-links li a').addClass('d-g-text');
        } else {
            Animation.start({
                width: '0%',
            });
            navTextAnimation.start({
                color: '#ddd'
            });
            $('.desktop-links li a').removeClass('d-g-text');
            $('.desktop-links li a').addClass('white-text');
        }
    });

    useEffect(() => {
        let elems = document.querySelectorAll('.sidenav');
        M.Sidenav.init(elems, {});

        if (window.innerWidth <= 992) {
            $('nav').removeClass('transparent');
            $('nav').addClass('white');
            
        } else {
            $('nav').removeClass('white');
            $('nav').addClass('transparent');

            if(window.innerWidth >= 992 && window.scrollY > 300) {
                Animation.start({
                    width: '100%',
                  
                });
                $('.desktop-links li a').removeClass('white-text');
                $('.desktop-links li a').addClass('d-g-text');
            } else {
                Animation.start({
                    width: '0%',
                });
                navTextAnimation.start({
                    color: '#ddd'
                });
                $('.desktop-links li a').removeClass('d-g-text');
                $('.desktop-links li a').addClass('white-text');
            }            
        }

        if(location.pathname === "/") {
            document.querySelector('.desktop-links').children[0].classList.add('active');
            document.querySelector('.sidenav').children[0].classList.add('active');
            $('.header-topic').text("GROWTH ALWAYS AND IN ALL WAYS!!!");
        } else if(location.pathname === "/about") {
            document.querySelector('.desktop-links').children[1].classList.add('active');
            document.querySelector('.sidenav').children[2].classList.add('active');
            $('.header-topic').text("WHO ARE WE");
            console.log('kkk')
        } else if(location.pathname === "/services") {
            document.querySelector('.desktop-links').children[2].classList.add('active');
            document.querySelector('.sidenav').children[4].classList.add('active');
            $('.header-topic').text("WHAT WE DO");
        } else if(location.pathname === "/our-team") {
            document.querySelector('.desktop-links').children[3].classList.add('active');
            document.querySelector('.sidenav').children[6].classList.add('active');
            $('.header-topic').text("MEET OUR TEAM");
        } else if(location.pathname === "/contact-us") {
            document.querySelector('.desktop-links').children[4].classList.add('active');
            document.querySelector('.sidenav').children[8].classList.add('active');
        }
       
    }, [width, Animation, navTextAnimation, location]);

    

    return ( 
        <>
            <div className="navbar-fixed transparent">
                <nav className="z-depth-0">
                    {/* ======logo bg==== */}
                    <motion.div className="logo-bg-3 main-dark-green hide-on-med-and-down"
                        variants={logoBgVariants}
                        initial="hidden"
                        animate="visible"
                        transition= {{ type: 'spring', duration: 1.05 }}
                    >

                    </motion.div>
                    <motion.div className="logo-bg-2 main-light-green hide-on-med-and-down"
                        variants={logoBgVariants2}
                        initial="hidden"
                        animate="visible"
                        transition= {{ type: 'spring', duration: 1.05, delay: 0.8 }}
                    >

                    </motion.div>
                    <motion.div className="logo-bg hide-on-med-and-down"
                        variants={logoBgVariants3}
                        initial="hidden"
                        animate="visible"
                        transition= {{ type: 'spring', duration: 1.05, delay: 1.2 }}
                    >
                    </motion.div>
                    <motion.div className="nav-cover hide-on-med-and-down"
                        animate={Animation}
                        transition= {{ type: 'spring', duration: 2.1, stiffness: 250}}
                    >
                    </motion.div>
                    {/* ======logo bg==== */}

                    <div className="nav-wrapper">
                        <div className="container">
                            <Link to="/" className="brand-logo">
                                <motion.img 
                                    alt="ZimSativa Logo"
                                    src={logo}  
                                    className="logo" 
                                    initial={{ scale: 0, opacity: 0}}
                                    animate={{ scale: 1, opacity: 1}}
                                    transition= {{ type: 'spring', duration: 1.05, delay: 2, stiffness: 200 }}
                                />
                            </Link>
                            <a href="#" className="sidenav-trigger" data-target="mobile-links">
                                <i className="material-icons d-g-text">menu</i>
                            </a>
                            <motion.ul 
                                className="desktop-links right hide-on-med-and-down"
                                initial={{ y: -40, opacity: 0}}
                                animate={{ y: 0, opacity: 1}}
                                transition= {{ type: 'spring', duration: 0.85, delay: 2, stiffness: 200 }}
                            >
                                <li>
                                    <Link to="/">Home</Link>
                                </li>
                                <li>
                                    <Link to="/about">About</Link>
                                </li>
                                <li>
                                    <Link to="/services">Services</Link>
                                </li>
                                <li>
                                    <Link to="/our-team">Our Team</Link>
                                </li>
                                <li>
                                    <Link to="/contact-us">Contact Us</Link>
                                </li>
                            </motion.ul>
                        </div>
                    </div>
                </nav>
            </div>
            <ul className="sidenav" id="mobile-links">
                <li>
                    <Link to="/">
                        <i className="material-icons white-text">home</i>
                        <span><p className="white-text">Home</p></span>
                    </Link>
                </li>
                <li>
                    <div className="divider"></div>
                </li>
                <li>
                    <Link to="/about">
                        <i className="material-icons white-text">location_city</i>
                        <span><p className="white-text">About</p></span>
                    </Link>
                </li>
                <li>
                    <div className="divider"></div>
                </li>
                <li>
                    <Link to="/services">
                        <i className="material-icons white-text">all_inclusive</i>
                        <span><p className="white-text">Services</p></span>
                    </Link>
                </li>
                <li>
                    <div className="divider"></div>
                </li>
                <li>
                    <Link to="/our-team">
                        <i className="material-icons white-text">group</i>
                        <span><p className="white-text">Our Team</p></span>
                    </Link>
                </li>
                <li>
                    <div className="divider"></div>
                </li>
                <li>
                    <Link to="/contact-us">
                        <i className="material-icons white-text">contacts</i>
                        <span><p className="white-text">Contact Us</p></span>
                    </Link>
                </li>
            </ul>
        </>
     );
}
 
export default Nav;