import { GoTelescope } from 'react-icons/go';
import { GiMountains } from 'react-icons/gi';

const AboutIntro = () => {
    return (
        <>
            <div className="container">
                <div className="center">
                    <h4 className="make-bold d-g-text heading-stroke make-inline-block mt-3">About Us</h4>
                    <p className="grey-text text-darken-2 constrain-p">ZimSativa is a leading <b>integrated Hemp Company</b> with robust operations across the Hemp value chain.</p>
                </div>
            </div>
            <div className="lime-cloud"></div>
            <div className="container">
                <div className="co-items z-depth-1 mt-3">
                    <div className="vision">
                        <div className="valign-wrapper">
                            <GoTelescope size="2.5rem" className="grey-text text-darken-2 mr-1"/>
                            <h5 className="make-medium make-inline-block mt-1 grey-text text-darken-2">Vision</h5>
                        </div>
                        <p className="grey-text text-darken-2 ">To be the foundation and pinnacle of the hemp industry. Providing sustainable, healthy, durable and organic future for Africa.</p>
                    </div>
                    <div className="mission main-background">
                        <div className="valign-wrapper">
                            <GiMountains size="2.5rem" className="grey-text text-lighten-3 mr-1"/>
                            <h5 className="make-medium make-inline-block mt-1 grey-text text-lighten-3">Mission</h5>
                        </div>
                        <p className="grey-text text-lighten-3">Our mission is simple and straight forward. Through safe and consistent methodologies we are using Hemp to build a legacy of sustainability and scientific innovation.</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AboutIntro;
