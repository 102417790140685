import greenCity from '../../assets/green-city.svg'
import { motion } from 'framer-motion';

const boxVariants = {
    hidden: {
        y: 50,
        opacity: 0
    },
    visible: {
        y: 0,
        opacity: 1,
        transition: { type: 'spring', duration: 1.75, delay: 0.8 }
    }
}

const IntroHome = () => {
    return ( 
        <div className="container">
            <motion.div 
                className="home-intro main-background z-depth-1"
                variants={boxVariants}
                initial="hidden"
                animate="visible"
            >
                <div className="intro-1">
                    <h4 className="make-bold d-g-text heading-stroke make-inline-block">ZimSativa</h4>
                    <p className="grey-text text-darken-2"><b>ZimSativa</b> was founded in 2020 by a group of focused <b>young professionals</b> who wanted to prove that there could be a better way to do things without living scratch marks on the <b>environment</b>.</p>
                    <p className="grey-text text-darken-2">We seek to show that it is possible to build a <b>sustainable future</b> whilst at the same time improving the lives of the community around you.</p>
                </div>
                <div className="intro-2 center">
                    <img src={greenCity} alt="Green City Illustration" className="green-city center-item"/>
                    <h6 className="make-bold make-inline-block grey-text text-darken-1">Building A Sustainable Future</h6>
                </div>
            </motion.div>
            <div className="center">
                <h4 className="make-bold d-g-text heading-stroke make-inline-block mt-3">What We Do</h4>
                <p className="grey-text text-darken-2 constrain-p">We seeks to be a fully <b>vertically integrated agribusiness</b>, being at the foundation and pinnacle of the <b>value chain</b>. </p>
            </div>
        </div>
     );
}
 
export default IntroHome;